
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { emptyToLine } from "@/utils/common";
import QRCode from "qrcodejs2";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const campaign = namespace("campaign");
const store = namespace("store");
const fission = namespace("fission");
@Component({
  components: { FileUpload, Editor }
})
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action queryCampaignDetail;
  @fission.Action queryFissionCampaign;
  @fission.Action synchronizeStores;
  dayjs = dayjs;
  emptyToLine = emptyToLine;
  campaignForm: any = {
    id: "111111",
    campaignName: "",
    campaignBeginTime: "",
    campaignEndTime: "",
    campaignImage: "",
    campaignDesc: "",
    isCoupon: "0",
    campaignContext: "",
    campaignImageColor: "rgb(251,132, 28)",
    prize: {
      bindCode: "",
      bindObj: {}
    },
    fissionPrize: {
      bindCode: "",
      cardTemplateResult: {},
      prizeAmount: "",
      prizeQty: ""
    },
    // 非业务直接参数
    time: [],
    bannerType: "default" // default customize
  };
  showLinkQr: boolean = false;
  get storeTypesList() {
    return this.campaignForm.storeTypesDesc ? this.campaignForm.storeTypesDesc.split(",") : [];
  }
  get couponInfo() {
    return this.campaignForm.prize ? this.campaignForm.prize.bindObj : {};
  }
  campaignFormRules: any = {};
  get breadData() {
    return [
      { name: t("v210801.fission-activity"), path: "/management/fission/fission-campagin" },
      {
        name: t("v210801.view-fission-activity")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "marketingCardTemplateAdd";
  }
  get campaignFullUrl() {
    return `${process.env.VUE_APP_H5_SITE}/wx-jump?storeCode=${this.storeData &&
      this.storeData.storeCode}&urlType=h5&url=${encodeURIComponent(
      process.env.VUE_APP_H5_SITE + "/campaign/promotion?campaignCode=" + this.campaignForm.campaignCode
    )}`;
  }
  created() {}
  mounted() {
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  init() {
    this.queryFissionCampaign({ campaignCode: this.id }).then(data => {
      console.log("[ data ]", data);
      this.campaignForm = data.data;
    });
  }
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  handleComfirmCommand(command) {
    if (command === "push") {
      this.$router.push(`/marketing/campaign/send-collect-customer/${this.campaignForm.campaignCode}`);
    } else if (command === "copyLink") {
      this.showLinkQr = true;
      setTimeout(() => {
        this.qrCode(this.campaignFullUrl);
      }, 200);
    }
  }
  handleDownloadQR() {
    var imgs = document.getElementById("qrcode").getElementsByTagName("img");
    var a = document.createElement("a");
    a.download = "QR" + new Date().getTime() || t("v210801.download-picture-name");
    a.href = imgs[0].src;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  handleEdit() {
    this.$router.push(`/management/fission/fission-campagin-add/${this.id}`);
  }
  async syncClick() {
    const res = await this.synchronizeStores({ campaignCode: this.campaignForm.campaignCode });
    if (res.code === "0") {
      this.$message.success(t("v210831.synchronization-succeeded"));
      this.init();
    }
  }
}
